<template>
    <div class="block">
        <div v-if="list" class="block-content">
            <div class="partial staff">
                <form id="search_form" class="staff__form">
                    <div class="row">
                        <div class="col-9 col-md-6 col-xl-9 pt-10">
                            <h2 class="partial__title">Сотрудники</h2>
                        </div>
                        <div class="col-3 col-md-6 col-xl-3 pt-10 text-right" v-if="current_user.is_admin">
                            <v-popover auto-hide="false" trigger="hover" placement="left" v-if="isCreateDisabled">
                                <button type="button" class="btn btn-rounded btn-primary-dark staff__add-btn" disabled>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 42 42" width="42" height="42">
                                        <path d="M42 19H23V0h-4v19H0v4h19v19h4V23h19z"></path>
                                    </svg>
                                    Добавить сотрудника
                                </button>
                                <div slot="popover" style="padding: 0 10px;">
                                    <b>Внимание</b>
                                    <div>
                                        Достигнут лимит по числу пользователей, пожалуйста, перейдите на другой тарифный план
                                    </div>
                                </div>
                            </v-popover>

                            <button v-else @click="openModal" type="button" class="btn btn-rounded btn-primary-dark staff__add-btn">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 42 42" width="42" height="42">
                                    <path d="M42 19H23V0h-4v19H0v4h19v19h4V23h19z"></path>
                                </svg>
                                Добавить сотрудника
                            </button>
                        </div>
                    </div>
                    <div class="row row--filters">
                        <div class="col-12 col-md-6 col-xl-6 mt-7">
                            <!--<div class="input-group">
                                <input type="text" class="form-control" placeholder="Поиск" v-model="search">
                                <button type="button" class="doc-base__filter-btn btn btn-secondary" @click="openSelectStructModal">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 20 20">
                                        <path d="M18.4 5c.553 0 .6-.448.6-1 0-.553-.047-1-.6-1H10v2h8.4zM1.6 3c-.551 0-.6.447-.6 1 0 .552.049 1 .6 1H5V3H1.6zM9 6.399v-4.8C9 1.046 8.552 1 8 1H7c-.553 0-1 .046-1 .599V6.4c0 .55.447.6 1 .6h1c.552 0 1-.05 1-.601zm-1 12v-4.801C8 13.046 7.552 13 7 13H6c-.553 0-1 .046-1 .599V18.4c0 .55.447.6 1 .6h1c.552 0 1-.05 1-.601zM13 13c.552 0 1-.05 1-.601v-4.8C14 7.046 13.552 7 13 7h-1c-.553 0-1 .046-1 .599V12.4c0 .55.447.6 1 .6h1zm5.4-2c.553 0 .6-.448.6-1 0-.553-.047-1-.6-1H15v2h3.4zM1.6 9c-.551 0-.6.447-.6 1 0 .552.049 1 .6 1H10V9H1.6zm16.8 8c.553 0 .6-.448.6-1 0-.553-.047-1-.6-1H9v2h9.4zM1.6 15c-.551 0-.6.447-.6 1 0 .552.049 1 .6 1H4v-2H1.6z"></path>
                                    </svg>
                                </button>
                                <button type="button" class="doc-base__search-btn btn btn-secondary" @click="loadAgain">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 512 512">
                                        <path d="M495 466.2L377.2 348.4c29.2-35.6 46.8-81.2 46.8-130.9C424 103.5 331.5 11 217.5 11 103.4 11 11 103.5 11 217.5S103.4 424 217.5 424c49.7 0 95.2-17.5 130.8-46.7L466.1 495c8 8 20.9 8 28.9 0 8-7.9 8-20.9 0-28.8zm-277.5-83.3C126.2 382.9 52 308.7 52 217.5S126.2 52 217.5 52C308.7 52 383 126.3 383 217.5s-74.3 165.4-165.5 165.4z"></path>
                                    </svg>
                                </button>
                            </div>-->
                            <div class="header__search mt-0">
                                <input v-model="search" type="text" class="header__search-input" placeholder="Поиск">
                            </div>
                        </div>
                        <div class="col-12 col-md-3 col-xl-3 mt-7 col--user-select staff-filter-user">
                            <v-select2
                                v-model="selectedStruct"
                                :options="structList"
                                v-on:input="loadAgain"
                                :clearable="false"
                                label="name"
                                style="width:100%"
                            >
                                <span slot="no-options">Ничего не найдено</span>
                            </v-select2>
                        </div>
                        <div class="col-12 col-md-3 col-xl-3 mt-7 col--user-select staff-filter-user--fixed">
                            <v-select2
                                v-model="status"
                                :options="statusOptions"
                                v-on:input="loadAgain"
                                label="name"
                                :clearable="false"
                                :searchable="false"
                                style="width:100%"
                            />
                        </div>
                    </div>
                </form>
                <div class="row">
                    <div class="col-sm-12">
                        <ul class="staff__list pb-20">
                            <row
                                @removeUser="popUser"
                                @updateUser="updateUser"
                                v-for="item in list" :data="item" :key="item.id"/>
                        </ul>
                    </div>
                    <loading-spinner
                        v-if="isLoading"
                        class="wall__spinner"
                    />
                    <mugen-scroll
                        :handler="infiniteScrollHandler"
                        :should-handle="!isLoading"
                    />
                </div>
                <div v-if="!list.length && !isLoading" class="col-sm-12">
                    <ul id="result_list_container" class="staff__list pb-20">
                        <div class="py-30 text-center">
                            <h1 class="h2 font-w700 mt-30 mb-10">Ничего не найдено</h1>
                        </div>
                    </ul>
                </div>
            </div>
        </div>
        <div v-else class="block block-rounded">
            <div class="block-content">
                <div class="content content-full">
                    <div class="py-30 text-center">
                        <h1 class="h2 font-w700 mt-30 mb-10">В этом разделе будут показываться все сотрудники.</h1>
                        <h2 class="h3 font-w400 text-muted mb-50">Пока у вас еще нет добавленных сотрудников.</h2>
                        <a v-if="current_user.is_admin" class="btn btn-success btn-rounded btn-alt-success js-edit-staff">
                            <i class="fa fa-plus mr-5"></i>
                            Добавить сотрудника
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import MugenScroll from 'vue-mugen-scroll';
    import {mapState} from "vuex";
    import session from '@/api/session';
    import debounce from '@/helpers/debounce'

    export default {
        name: "Content",
        components: {
            Row : () => import('./Row'),
            MugenScroll,
            LoadingSpinner : () => import('@/components/LoadingSpinner')
        },
        data() {
            return {
                statusOptions: [
                    {
                        name: 'Работает',
                        value: 'works'
                    },
                    {
                        name: 'Уволен',
                        value: 'fired'
                    },
                    {
                        name: 'Все',
                        value: 'all'
                    },
                ],
                structList: [
                    {
                        name: 'Все сотрудники',
                        id: null
                    }
                ],
                selectedStruct: {
                    name: 'Все сотрудники',
                    id: null
                },
                nextData: '/api/v1/users/',
                list: [],
                isLoading: false,
                search: '',
                structure: null,
                status: {
                    name: 'Работает',
                    value: 'works'
                },
                isStaffEmpty: false,
                timer: null,
                isCreateDisabled: false
            }
        },
        computed: {
            ...mapState('default_data', [
                'current_user'
            ]),
        },
        watch: {
            search(value) {
                if(value.length >= 3) {
                    this.debouncedSearch()
                } else if(value.length === 0) {
                    this.debouncedSearch()
                }
            },
            deep: true
        },
        created: function () {
            this.debouncedSearch = debounce(this.loadAgain, 500)
        },
        methods: {
            popUser(user) {
                this.list.splice(this.list.indexOf(user), 1);
            },
            updateUser(data) {
                this.list.splice(this.list.indexOf(data.user), 1, data.new_user);
            },
            async infiniteScrollHandler() {
                try {
                    if (!this.nextData) {
                        return false;
                    }
                    this.isLoading = true;

                    clearTimeout(this.timer);
                    this.timer = setTimeout(async () => {
                        await this.loadList();
                        this.isLoading = false
                    }, 500);

                } catch(error) {
                    console.error(error);
                    this.isLoading = false;
                }
            },
            async loadList() {
                try {
                    let params = {
                        search: this.search.length ? this.search : null,
                        structure: this.selectedStruct.id,
                        active: this.status.value
                    };

                    const request = session.get(this.nextData, {params});
                    const response = await request;

                    this.list.push(...response.data.results);
                    this.nextData = response.data.next;

                } catch(error) {
                    console.error(error)
                }
            },
            loadAgain() {
                this.list = [];
                this.nextData = '/api/v1/users/';
                this.infiniteScrollHandler();
            },
            openModal() {
                const ChangeStaffModal = () => import(`../detail/ChangeStaffModal`);
                this.$modal.show(ChangeStaffModal, {
                }, {
                    name: 'change-staff-modal',
                    styles:{ overflow: "inherit", },
                    scrollable: true,
                    height: 'auto',
                    adaptive: true,
                    maxWidth: 740,
                    width: '100%',
                    clickToClose: false
                }, {
                    'before-close': this.updateItem
                });
            },
            openSelectStructModal() {
                const SelectStructModal = () => import(`../detail/SelectStructModal`);
                this.$modal.show(SelectStructModal, {
                    structList: this.structList,
                    selectStruct: this.selectStruct,
                    selectedStruct: this.selectedStruct
                }, {
                    name: 'select-struct-modal',
                    scrollable: true,
                    height: 'auto',
                    adaptive: true,
                    maxWidth: 740,
                    width: '90%',
                    clickToClose: true
                })
            },
            selectStruct(id,name) {
                this.selectedStruct = {
                    id,
                    name
                };
                this.loadAgain()
            },
            async updateItem(data) {
                if (data.params) {
                    this.list.push(data.params.user);
                }
            },
        },
        async mounted() {
            try {
                let usersCount, current_rate, request;

                request = await session.get(`/api/v1/structure/select/`);
                this.structList.push(...request.data);

                request = await session.get(`/api/v1/users/count/`);
                usersCount = request.data.count;

                request = await session.get(`/api/v1/rate/current_rate/`);
                current_rate = request.data;

                if (usersCount >= 5 && current_rate.is_free_rate && !current_rate.exclude_domain) {
                    this.isCreateDisabled = true;
                }
                this.infiniteScrollHandler();
            } catch(error) {
                console.error(error)
            }
        },
    }
</script>

<style lang="scss">
    @import "#sass/v-style";

    /* mobile first */
    .staff {
        position: relative;
        .vs__dropdown-toggle {
            min-height: 100%;
        }
    }

    .staff-rec {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        min-height: 75px;
        padding-top: 15px;
        border-bottom: 1px solid rgb(232, 232, 232);
        font-size: 1rem;
        color: #000;
    }

    .staff-rec:hover {
        background-color: rgb(246, 247, 249);
    }

    .staff__list {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    .staff-rec__user {
        position: relative;
        min-height: 52px;
        padding-left: 60px;
    }

    .staff-rec__user-img {
        position: absolute;
        top: -5px;
        left: 0;
        width: 45px;
        height: 45px;
        border-radius: 50%;
    }
    .staff-rec__user-name {
        display: inline-block;
        margin-right: 10px;
        width: auto;
        font-size: 1rem;
        color: rgb(33, 115, 211);
        font-weight: 600;
    }
    .staff-rec__user-position {
        display: block;
        font-size: 0.875rem;
        color: rgb(153, 153, 153);
    }
    .staff-rec__user-img {
        top: 0;
        margin-right: 20px;
    }
    .staff-rec__contact {
        display: none;
    }
    .staff-rec__mail {
        position: relative;
        margin-bottom: 4px;
    }
    .staff-rec__mail-visible {
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .staff-rec__mail-hidden {
        position: absolute;
        top: 0;
        left: -10px;
        display: none;
        padding: 2px 11px 5px 8px;
        font-size: 16px;
        line-height: 19px;
        color: #000000;
        background: #FFFFFF;
        border: 1px solid #EEEEEE;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.16);
        border-radius: 3px;
        box-sizing: border-box;
        cursor: pointer;
    }
    .staff-rec__mail:hover .staff-rec__mail-hidden {
        display: block;
    }
    .staff-rec__mail svg,
    .staff-rec__phone svg {
        margin-right: 8px;
        fill: rgb(204, 204, 204);
    }
    .staff-rec__department {
        display: none;
    }
    .staff-rec__active {
        display: none;
        padding: 0 15px;
        color: rgb(87, 152, 16);
    }
    .staff-rec__active_false {
        color: rgb(208, 2, 27);
    }
    .staff-rec__menu-btn {
        position: absolute;
        top: 28px;
        right: 0;
    }
    .form-control {
        height: 38px;
        border: 1px solid #e9e9e9;
    }
    .staff__add-btn {
        height: 30px;
        padding: 6px 8px;
        font-size: 0;
        background-color: transparent;
        display: inline-flex;
        align-items: center;
    }
    .staff__add-btn svg {
        width: 12px;
        height: 12px;
    }
    .staff__add-btn i {
        font-size: 0.875rem;
    }
    .doc-base__search-btn,
    .doc-base__filter-btn {
        position: absolute;
        z-index: 10;
        right: 10px;
        height: 38px;
        padding-left: 5px;
        padding-right: 5px;
        border: 1px solid transparent;
        background-color: transparent;
        color: #ccc;
    }
    .doc-base__filter-btn {
        right: 35px;
    }
    .doc-base__search-btn svg,
    .doc-base__filter-btn svg {
        fill: #ccc;
    }
    .doc-base__search-btn:hover svg,
    .doc-base__filter-btn:hover svg {
        fill: #212529;
    }
    .staff-filter-user {
        display: none;
    }
    .staff-filter-user--fixed {
        display: flex;
        margin-top: 15px;
    }

    .staff-filter-header {
        padding-top: 14px;
        padding-bottom: 14px;
        border-bottom: 1px solid #e8e8e8;
    }
    .staff-filter-header .block-title {
        font-weight: bold;
    }
    .staff-filter-header .close-modal {
        top: 10px;
    }
    .staff-filter-radio {
        display: block;
        font-size: 1rem;
        line-height: 28px;
        font-weight: 400;
        color: #000;
    }
    .staff-filter-radio input {
        position: absolute;
        z-index: -1;
        opacity: 0;
    }
    .staff-filter-radio__icon {
        display: inline-block;
        vertical-align: middle;
        width: 15px;
        height: 15px;
        margin-right: 5px;
        margin-top: -2px;
        border-radius: 50%;
        border: 1px solid #e9e9e9;
        transition: background-color 0.25s, border-color 0.25s;
    }
    .staff-filter-radio input:checked + .staff-filter-radio__icon {
        position: relative;
        background-color: #FCC54D;
        border-color: #FCC54D;
    }

    .staff-filter-radio input:checked + .staff-filter-radio__icon:before {
        content: "";
        position: absolute;
        top: 2px;
        left: 3px;
        width: 10px;
        height: 6px;
        border-left: 2px solid #fff;
        border-bottom: 2px solid #fff;
        transform: rotate(-45deg);
    }
    .staff-filter-content {
        height: 290px;
        overflow-y: auto;
    }
    .staff__form {
        margin-bottom: 15px;
    }

    @media (min-width: 768px) {
        .staff-filter-user {
            display: flex;
        }
        .staff-filter-user--fixed {
            margin-top: 0;
        }
        .doc-base__filter-btn {
            display: none;
        }
        .doc-base__search-btn {
            right: 0;
            padding-left: 15px;
            padding-right: 15px;
            color: #212529;
        }
        .staff__add-btn {
            height: 34px;
            padding: 8px 14px;
            font-size: 1rem;
        }
        .staff__add-btn svg {
            width: 10px;
            height: 10px;
            margin-right: 5px;
        }
        .staff-rec__user-img {
            width: 60px;
            height: 60px;
        }
        .staff__add-btn i {
            margin-right: 5px;
        }
        .staff-rec {
            flex-wrap: nowrap;
            flex-shrink: 0;
            flex-grow: 0;
            align-items: center;
            padding: 16px 0;
            min-width: 93px;
        }
        .staff-rec__user {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            padding-right: 15px;
            width: 40%;
            padding-left: 70px;
        }
        .staff-rec__contact {
            display: block;
            padding: 0 105px 0 5px;
            width: 30%;
            white-space: nowrap;
        }
        .staff-rec__department {
            display: block;
            padding: 0 10px 0 20px;
            width: 22%;
        }
        .staff-rec__menu-btn {
            top: 0;
            bottom: 0;
            height: 20px;
            margin: auto 0;
        }
    }

    @media (min-width: 1366px) {
        .staff-rec {
            min-height: 96px;
        }
        .staff-rec__menu-btn {
            top: 0;
        }
        .staff-rec__user-name {
            width: auto;
        }
        .staff-rec__active {
            display: block;
        }
        .staff-rec__menu-btn {
            position: relative;
            margin-left: auto;
        }
        .staff-rec__user {
            width: 35%;
        }
        .staff-rec__contact {
            width: 20%;
        }
    }

    /* btn-menu */
    .btn-menu-wrap {
        display: flex;
    }
    .btn-menu-open {
        font-size: 0;
        width: 18px;
        height: 18px;
        border: none;
        background-color: transparent;
        background-image: url("../../../assets/img/favicons/three-point.png");
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;
        cursor: pointer;
    }
    .btn-menu-open_horizontal {
        width: 18px;
        height: 10px;
        margin-top: 0;
        margin-left: 12px;
        background-image: url("../../../assets/img/favicons/three-point-horizontal.png");
    }
    .btn-menu-inner {
        display: block;
        position: absolute;
        z-index: 10;
        top: 42px;
        left: -162px;
        align-items: center;
        padding: 0 10px;
        width: 190px;
        border-radius: 4px;
        background-color: #fff;
        box-shadow: 0 0 10px rgba(50, 50, 50, 0.22);
    }
    .btn-menu-inner-off {
        display: none;
    }
    .triangle-shadow {
        position: absolute;
        top: -13px;
        right: 10px;
        overflow: hidden;
        width: 24px;
        height: 13px;
    }
    .triangle-shadow::after {
        content: '';
        position: absolute;
        width: 15px;
        height: 15px;
        background-color: #fff;
        transform: rotate(45deg);
        top: 5px;
        left: 5px;
        box-shadow: 0 0 6px 0 rgba(50, 50, 50, 0.22);
    }
    .btn-menu-open_horizontal +
    .btn-menu-inner {
        top: 6px;
        left: -125px;
    }
    .btn-menu-one {
        display: flex;
        justify-content: left;
        width: 164px;
        height: 36px;
        border: none;
        background-color: transparent;
        font-size: 12px;
        line-height: 1;
        cursor: pointer;
    }
    .btn-menu-one svg {
        margin-right: 12px;
        fill: #ccc;
        stroke: #ccc;
        width: 13px;
        height: 13px;
    }
    .btn-menu-one:hover svg {
        fill: #555;
        stroke: #555;
    }
    .staff-rec__edit svg {
        fill: rgb(240, 180, 52);
        stroke: rgb(240, 180, 52);
    }
    .staff-rec__send-key svg {
        fill: rgb(74, 144, 226);
        stroke: rgb(74, 144, 226);
    }
    .staff-rec__dell svg {
        fill: rgb(208, 2, 27);
        stroke: rgb(208, 2, 27);
    }
    .col--user-select {
        .v-select {
            width: 100%;
        }
        .vs__dropdown-toggle {
            cursor: pointer !important;
        }
    }

    @media (min-width: 1366px) {
        .btn-menu-one {
            font-size: 0;
            width: 27px;
            height: 27px;
            border-radius: 50%;
            transition: background-color 0.26s cubic-bezier(0.47, 0, 0.745, 0.715);
        }
        .btn-menu-one.staff-rec__edit:hover {
            background-color: rgb(240, 180, 52);
        }
        .btn-menu-one.staff-rec__send-key:hover {
            background-color: rgb(74, 144, 226);
        }
        .btn-menu-one.staff-rec__dell:hover {
            background-color: rgb(208, 2, 27);
        }
        .btn-menu-one svg {
            margin: auto;
            fill: #ccc;
            stroke: #ccc;
        }
        .btn-menu-one:hover svg {
            fill: #fff;
            stroke: #fff;
        }
        .btn-menu-open {
            display: none;
        }
        .btn-menu-inner {
            display: flex;
            flex-direction: row;
            background: none;
            position: relative;
            top: auto;
            left: auto;
            width: auto;
            height: auto;
            padding-top: 0;
            box-shadow: none;
        }
        .btn-menu-open_horizontal +
        .btn-menu-inner {
            top: 0;
            left: 0;
        }
        .triangle-shadow {
            display: none;
        }
    }

    @media (min-width: 1600px) {
        .row--filters {
            position: absolute;
            top: 7px;
            left: 147px;
            width: 65%;
        }
    }

</style>
